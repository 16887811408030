import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import { ApiError, ApiResult, ExtendedApiResult } from 'common/api/model';
import { maybeElement as mbel } from 'common/utils/iterable';

import {
  getNotificationsInput,
  getNotificationInput,
  NotificationLiteModel,
  NotificationModel,
  BadgesModel,
} from './model';

export const notificationKey = {
  notificationsKey: 'getNotifications',
  notificationKey: 'getNotification',
  notifications(input?: getNotificationsInput) {
    return [notificationKey.notificationsKey, ...mbel(!!input, input?.params)];
  },
  notification(input?: getNotificationInput) {
    return [notificationKey.notificationKey, ...mbel(!!input, input?.id)];
  },
  badgesKey: 'getBadges',
  badges() {
    return [notificationKey.badgesKey];
  },
};

const ENDPOINT = 'notifications' as const;

export function useGetNotifications(
  input?: getNotificationsInput,
  options?: UseInfiniteQueryOptions<
    ExtendedApiResult<NotificationLiteModel[]>,
    ApiError
  >,
): UseInfiniteQueryResult<
  ExtendedApiResult<NotificationLiteModel[]>,
  ApiError
> {
  return QueryTransformer(
    useInfiniteQuery(
      notificationKey.notifications(input),
      ({ pageParam = 1 }) => {
        return QueryFetchFunction({
          url: ENDPOINT,
          params: {
            ...input?.params,
            page: pageParam,
          },
        });
      },
      options,
    ),
    NotificationLiteModel,
  );
}

export function useGetNotification(
  input?: getNotificationInput,
  options?: UseQueryOptions<ApiResult<NotificationModel>, ApiError>,
): UseQueryResult<ApiResult<NotificationModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      notificationKey.notification(input),
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input?.id}`,
        }),
      options,
    ),
    NotificationModel,
  );
}

export function useGetBadges(
  options?: UseQueryOptions<ApiResult<BadgesModel>, ApiError>,
): UseQueryResult<ApiResult<BadgesModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      notificationKey.badges(),
      () =>
        QueryFetchFunction({
          url: `me/badges`,
        }),
      options,
    ),
    BadgesModel,
  );
}
