import { IdInput, getParamsInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';
import { NotificationTypeEnum } from 'common/constants/enum';

export class NotificationLiteModel {
  id: string;
  type: NotificationTypeEnum;
  title: string;
  description: string;
  redirect: string;

  @Expose({ name: 'read_at' })
  @Type(() => Date)
  readAt: Date | null;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;
}

export class NotificationModel extends NotificationLiteModel {}

export class BadgeCountModel {
  @Type(() => Number)
  count: number;
}

export class BadgesModel {
  @Type(() => BadgeCountModel)
  cart: BadgeCountModel;

  @Type(() => BadgeCountModel)
  notification: BadgeCountModel;
}

export type getNotificationsInput = getParamsInput;

export type getNotificationInput = IdInput;

export type ReadNotificationInput = {
  notificationId: string;
};
